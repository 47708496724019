import React, { useEffect } from 'react'
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'

const ContactForm = () => {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
    
        script.addEventListener('load', () => {
        if (window.hbspt) {
            window.hbspt.forms.create({
            region: "na1",
            portalId: "7346852",
            formId: "2ed9abdf-b931-4e5c-a1a5-b052d994c2ea",
            target: ".contact-form"
            });
        }
        });
    }, []);

    return (
        <section className="contact-area pb-100">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                        Get in Touch
                    </span>
                    <h2>Ready to Get Started?</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <img src={contact} alt="contact" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm